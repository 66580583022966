.App {
  text-align: center;
  
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@font-face {
  font-family: "lexReg";
  src: url('./fonts/Lexend/Lexend-Regular.ttf');
}
@font-face {
  font-family: "lexReg2";
  src: url('./fonts/Lexend/Lexend-Regular\ 2.ttf');
}
@font-face {
  font-family: "lexSemi";
  src: url('./fonts/Lexend/Lexend-SemiBold.ttf');
}
@font-face {
  font-family: "lexSemi2";
  src: url('./fonts/Lexend/Lexend-SemiBold\ 2.ttf');
}
@font-face {
  font-family: "lexSemi3";
  src: url('./fonts/Lexend/Lexend-SemiBold\ 3.ttf');
}
@font-face {
  font-family: "lexThin";
  src: url('./fonts/Lexend/Lexend-Thin.ttf');
}
@font-face {
  font-family: "lexMed";
  src: url('./fonts/Lexend/Lexend-Medium.ttf');
}
@font-face {
  font-family: "lexLight";
  src: url('./fonts/Lexend/Lexend-Light.ttf');
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.row{
  width: 100%;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.upper{
  text-transform: uppercase;
}
.pr-2{
  padding-right: 2%;
}
.blue-color{
    color: #073448;
}
.green-color{
  color: #149444;
}
.black-color{
  color: #000000;
}
.reg-text{
font-family: 'lexReg';
}
.reg-text-2{
  font-family: 'lexReg2';
  }
.semi-bold{
  font-family: 'lexsemi';
}
.semi-bold-2{
  font-family: 'lexSemi2';
}
.semi-bold-3{
  font-family: 'lexSemi3';
}
.light-text{
  font-family: 'lexThin';
}
.med-text{
  font-family: 'lexMed';
}
.light-text2{
  font-family: 'lexLight';
}