@font-face {
    font-family: "lexSemi";
    src: url('../../../fonts/Lexend/Lexend-SemiBold.ttf');
  }
.home-container{
    padding: 8% 15% 5% 15%;
   
}

.img-link{
    width:100%;
}
.rmdp-range {
    background-color: transparent!important;
    box-shadow: 0 0 3px transparent !important;
    color: #c7c7c7 !important;
}
.rmdp-day.rmdp-deactive, .rmdp-day.rmdp-disabled {
    color: #c7c7c7 !important;
}
.rmdp-calendar {
    height: max-content;
    padding: 4px;
    padding-top: 0px !important;
    font-family: "lexSemi" !important;
    /* width: auto !important; */
}
.rmdp-header {
    font-size: 18px !important;
    font-family: "lexSemi" !important;
    margin-top: 0px !important;
    padding: 20px 0 !important;        
    background: #eee !important;
}
.rmdp-week, .rmdp-ym {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    font-family: "lexSemi" !important;
}
.rmdp-week-day {
    color:#073448 !important;
    cursor: default;
    font-size: 13px;
    font-family: "lexSemi" !important;
    font-weight: 500;
}
.rmdp-header-values span {
    color: #000;
    margin: auto;
    /* font-size: "30px" !important; */
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: rgba(0, 123, 255, 0.25) !important;
    color: #fff;
}
.rmdp-day.rmdp-today span {
    background-color: #60aac76a !important;
    color: #fff;
    font-family: "lexSemi" !important;
}

.cards-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
}
.react-datepicker{
    /* display: flex; */
    align-items: center;
}
.react-datepicker-popper {
    z-index: 99 !important;
}
/* .react-datepicker__view-calendar-icon input {
    padding: 6px 10px 5px 25px;
    width: inherit !important;
    margin-bottom: 10px;
    display: flex;
    align-items: center !important;
    border: 0;
} */
.rdrDateDisplayWrapper {
    display: none;
}
.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #073448;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    padding:2px 2px;
    margin-bottom: 10px !important;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    /* display: table-cell; */
    vertical-align: middle;
    content: "×";
}
.css-1nmdiq5-menu {
    z-index: 999 !important ;
}
.react-datepicker__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 6px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    /* display: table-cell; */
    vertical-align: middle;
    align-items: center;
    display: flex;
}
.rdrMonth{
width: 100% !important;
}

.rdrCalendarWrapper {
width: 100%;
}


:root {
    --fc-border-color: #073448;
    --fc-daygrid-event-dot-width: 5px;
  }
  .fc{
    height: 500px;
    /* width: 400px; */
  }
  .fc td {
    background: #ebedef;
    /* height: 10px; */
  }
  .fc th {
    text-align: center;
    background: #ebedef;

}
.swiper-button-next{
    position: relative;
    top:38%  !important;
    left:99% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-button-prev{
    position: relative;
    top:40%  !important;
    left:-7.5% !important;
width:88px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}


.card-img-top{
   margin-left: 2px !important;
}


.swiper-button-next {
    width: 35px !important;
    background-image: url('../../../../public/prev.png') !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    position: absolute;
    transform: scaleX(-1) !important;
    right: -2.5rem !important;
    left: auto !important;
}
.swiper-button-prev {
    width: 35px !important;
    background-image: url('../../../../public/prev.png') !important;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    position: absolute;
    left: -2.6rem !important;
    right: auto !important;
}




.active,
.active:hover {
    color:#149444;
   text-decoration: none;
   cursor: pointer;
}
#horizintal{
    position: relative;
 
   width: 100%;
    padding: .7px;
    background-color: #07344883;
    font-weight: bold;
}

.silder-row{
    width: 100%;
    padding-left: 6%;

}
.mrg{
    margin-left:6%;
    margin-right: 12%;
}
.mrg-slide{
    padding-left:3%;
    padding-right: 0%;
}
.active-line{
   
    position:absolute;
    top: 1.5rem;
    padding: .6px;
    background-color: #149444;
}
.active-c{
    width: 80px;
}
.active-det{
    width: 50px;
}
.active-d{
    width: 133px;
    left: 6.4rem;
}
.color-p,
.color-p:hover{
    font-family: "lexsemi";
    text-decoration: none;
    
}
.home-a,.home-a:hover
{
    font-family: "lexsemi";
    text-decoration: none;
    cursor: pointer;
}
@media(max-width:600px)
{
    .home-container{
        padding: 16% 15% 5% 15%;
       
    }
    .color-p{
        font-size: 14px;
    }
}
@media(max-width:400px)
{
    .home-container{
        padding: 16% 15% 5% 15%;
       
    }
    .color-p,.home-container a{
      font-size: 13px;
    }
}





@media(max-width:600px)
{

    
    .cards-container{
        padding-left:25px !important;
    }
    .top-destinations-txt{
        /* padding-right: 120px; */
    }
   

   .find-spaces{
   
   /* padding-right: 200px; */
    font-size: 16px;
   
}


}

@media (min-width:1600px){
    .top-destinations-ctr{
        max-width: 1240px !important;
        margin-left: 75px !important;
    }
}



@media (min-width: 1200px){
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 900px;
    }
    }
    @media (min-width: 1400px){
        .container, .container-sm, .container-md, .container-lg, .container-xl {
            max-width: 1140px;
        }
        }