.form-control-cust {
  border: 1px solid #5c5955;
  border-radius: 1px;
  margin-bottom: 4%;
  padding: 2%;

  width: 100%;
}
.re-buuton {
  height: 40px;
  width: 100%;
  font-size: 92%;
  color: black;
}
