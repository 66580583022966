
 .full-email-input{
  height: 55px !important;
 }

.info-container-mobile{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
 height:200px;
  width:100%;
}

.social-footer-mobile{
  display:flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding:0px 20px 20px 0px;
  width:150px;
}

.FAQ-link{
  color: #ffffff;
  font-size: 24px;
  font-weight:bold;
  
}

.FAQ-link::after{
 text-decoration: none;
 color: white;
}

.FAQ-link:hover{
  text-decoration: none;
  color: white;
 }
.social-footer-mobile>a{
font-size: 25px;
}

.copyright-mobile-ctr
{
  padding:20px
}



.mobile-footer-headers{
  font-size: 18px;
  font-weight:bold;
  text-transform: uppercase;
}

.mobile-footer-legend{
font-size: 12px;
}
.mobile-footer-legend a{
  /* font-size: 12px; */
  font-size: 12px;
  margin-left: 4px;
  color: #ffffff;
   text-decoration: underline;
   /* line-height: 6px; */
  }


.footer-mobile {
  background-color: #073448;
  color: #ffffff;
 
}

.info1{
width:45%;
height:100px;
padding: 5px;
}
.info2{
  width: 45%;
  height:100px;
  padding: 5px;
}




.footer {
  background-color: #073448;
  color: #ffffff;
  padding: 4% 0% 4% 0%;
}




.title-bold {
  text-transform: uppercase;
  padding-bottom: 7%;
  font-weight: bold;
}
.footer i {
  font-size: 150%;
}

.footer-socials {
  color: white;
  transition: transform 0.2s ease-in-out;
  -webkit-transition: transform 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
}

.footer-socials:hover {
  transform: scale(1.2);
}

.policies-sec {
  font-size: 12px;
  padding-top: 2%;
  padding-right: 70%;
  margin-left: 14px;
}
.policies-sec a {
  font-size: 12px;
  margin-left: 4px;
  color: #ffffff;
   text-decoration: underline;
}

.pad-top-7 {
  padding-top: 7%;
}

.search-container {
  background-color: #149444;
  padding: 4% 0% 4% 0%;
  color: #ffffff;
  display: flex;
    justify-content: center;
  
}
.sec-text {
  font-family: "lexReg";
  font-weight: bold;
}
.sreach {
  margin-left: 15%;
}

.sreach .form-control {
  border-radius: 8px;
  padding: 25px 0px 25px 20px;
}
.search-btn {
  font-size: 15px;
  width: 120px;
  background-color: #073448;
  font-family: "lexReg";
  border: 1px solid #707070;
  border-radius: 0px 7px 7px 0px;
  color: #ffffff;
  -webkit-border-radius: 0px 7px 7px 0px;
  -moz-border-radius: 0px 7px 7px 0px;
  -ms-border-radius: 0px 7px 7px 0px;
  -o-border-radius: 0px 7px 7px 0px;
}
.list {
  background-image: url("../../../images/Component 1 – 1.jpg");
  
 background-size:cover;
  background-repeat: no-repeat;
  background-position:center;

}

.list-container {
  padding: 7% 30% 10% 30%;
  color: #ffffff;
}
.list-btn {
  justify-content: center;
  margin-top: 5%;
}
.list-btn button {
  border: none;
  border-radius: 6px;
  padding: 1.3% 4% 1.3% 4%;
  color: #073448;
  font-weight: bold;
  font-size: 110%;
  font-family: "lexReg";
}
@media (max-width: 1300px) {
  .sreach .form-control {
    font-size: 20px;
  }
  .sreach .input-group {
    width: 80%;
  }
  .sreach .form-control {
    
    border-radius: 8px;
    padding:25px 0px 25px 10px;
  }
  .text-reg {
    font-size: 14px;
  }
  .title-bold {
    font-size: 14px;
  }
  .footer i {
    font-size: 14px;
  }
  .policies-sec {
    font-size: 12px;
    padding-right: 68%;
    margin-left: 14px;
  }
  .sec-text {
    font-size: 120%;
  }
  .search-btn {
    width: 100px;
    font-size: 14px;
  }
  .sreach .form-control {
    font-size: 80%;
  }
  .sreach .input-group {
    width: 90%;
  }
 
}
@media(max-width:1200px)
{
  .sreach .form-control {
    font-size: 13px;
  }
  .sreach .input-group {
    width: 80%;
  }
  .sreach .form-control {
    
    border-radius: 8px;
    padding:25px 0px 25px 10px;
  } 
}

@media(min-width:1800px)
{
 .footer-container{

 width:100% !important;
 max-width: 1400px !important;
 }

 .container{
  max-width: 1400px !important;
 }
 .sreach {
  margin-left: 15% !important;
}


}


@media (max-width: 1100px) {
  .sreach .form-control {
    font-size: 12px;
  }
  .sreach .input-group {
    width: 80%;
  }
  .sreach .form-control {
    
    border-radius: 8px;
    padding:25px 0px 25px 10px;
  }
  .sec-text {
    font-size: 95%;
  }
  .search-btn {
    width: 70px;
    font-size: 12px;
  }
  .sreach .form-control {
    font-size: 80%;
  }
  .sreach .input-group {
    width: 80%;
  }
 
}
@media (max-width: 1000px) {
  .sreach .form-control {
    font-size: 12px;
  }
  .sreach .input-group {
    width: 80%;
  }
  .sreach .form-control {
    
    border-radius: 8px;
    padding:25px 0px 25px 10px;
  }
  .info{
    margin-bottom: 30px;
  }
  
  .text-reg {
    font-size: 12px;
    padding-bottom: 3%;
  }
  .title-bold {
    font-size: 12px;
    padding-bottom: 3%;
  }
  .footer i {
    font-size: 14px;
  }
  .policies-sec {
    font-size: 10px;
    padding-right: 45%;
    margin-left: 14px;
    margin-top: 10px;
  }
  .pad-top-7 {
    padding-top: 3%;
  }
}
@media(max-width:900px)
{
  .sreach .form-control {
    font-size: 60%;
  }
  .sreach .input-group {
    width: 80%;
  }
  .sreach .form-control {
    
    border-radius: 8px;
    padding:20px 0px 20px 10px;
  }
  .policies-sec {
    font-size: 10px;
    padding-right: 50%;
    margin-left: 14px;
    margin-top: 10px;
  }
}
@media (max-width: 700px) {
  .sreach .form-control {
    
    border-radius: 8px;
    padding:20px 0px 20px 10px;
  }
  .policies-sec{ 
    margin-top: 10px;
    padding-right: 45%;
  }
  .sec-text {
    font-size: 70%;
  }
  .search-btn {
    width: 100px;
    font-size: 10px;
  }
  .sreach .form-control {
    font-size: 60%;
  }
  .sreach .input-group {
    width: 95%;
  }
  
  .list {
    text-align: center;
  }

  .list-container {
    padding: 7% 20% 10% 30%;
    color: #ffffff;
    width: 100%;
  }

  .foo-logo{
    width:70px;
  }

  .get-offers{
    font-size: 1rem;
  }

  .h3-list-property{
    font-size: 0.9rem;
    position: relative;
    left: 0px;
    width: 500px;
  }

  .list-property{
    position: relative;
    left:-0px;
  }

  .swiper-button-next{
    margin-left:-10% !important;
  }

  
  
}

@media(max-width:600px)
{



  .search-container{
    padding: 0 !important;
  }
  .sreach{
    padding: 0 !important;
    margin: 0 !important;
    max-width: 100% !important;
    height:80px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px !important;

  }

 .full-email-input{
  height: 50px !important;
 }

  .email-input{
   
   
    margin: 0 !important;
  }

  .footer2-ctr{
margin: 0 !important;
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;

  }
.get-offers{
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 700 !important;
  font-size: 18px !important;
 text-align: left;
  line-height:2.2ch;
}

 .form-control::placeholder{
  font-size: 15px !important;
  color: black  !important;
  
 
 }

  .sreach .form-control {
    
    border-radius: 8px;
    padding:18px 0px 15px 10px;
  }
  .policies-sec{ 
    margin-top: 10px;
    padding-right: 10%;
  }
  .list-property{
  width: 270px;
  height: 50px ;
  font-size: 20px !important;
  margin: 40px 20px;
  margin-bottom: 120px;
  border-radius: 10px !important;
  background-color: #ffffff;
  }

  .get-offers-ctr{
   
    padding:0px 30px 10px 0px !important;
    width: 95% !important;
  }

  .list-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
   padding: 50px 30px 10px 30px;
    color: #ffffff;
    max-width: 100% !important;
  }
  .footer1-ctr{
    margin: 0 !important;
  }

  .h3-list-property{
    width: 100% !important;
    margin: 0 !important;
    padding-bottom: 0px;
    font-weight: 700 !important;
    font-size: 18px !important;
   
    line-height:2.2ch;
  }

  .search-footer{
    width:100% !important;
  }

  .list-btn{
    margin: 0px !important;
    width: 100% !important;
  }
  .list-btn button {
    padding: 1% 4% 1% 4%;
  }


  .foo-logo{
    width:70px;
  }

  

 

  .swiper-button-next{
    margin-left:-10% !important;
  }

  
  
}



@media (min-width:1800px){
  .foo-logo{
    margin-left: -25px !important;
  }
  .search-row{
    width: 95% !important;
  }

 

  .search-container {
    background-color: #149444;
    padding: 4% 0% 4% 0%;
    color: #ffffff;
   
    
  }
}

@media (max-width:468px){
  .search-btn{
    font-size: 15px !important;
  }
}

@media (min-width:768px){
  .get-offers-ctr{
    padding-left: 0px !important;
  }
}