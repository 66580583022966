.swiper-button-next {
  width: 60px;
  background-image: url(../../../svg/Group\ 233.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
    position: absolute;
}
.swiper-button-next::after {
  display: none;
}
.swiper{
  margin-left: auto;
  margin-right: auto;
  position: unset;
  overflow: hidden;
  list-style: none;
  padding: 0;
  right: -2rem;
  width: 100%; 
  height: 100%;
}

.swiper-button-prev {
  width: 80px;
  background-image: url(../../../svg/Group\ 232.svg);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  position: absolute;
  left: -5rem;
}
.swiper-button-prev::after {
  display: none;
}
@media(max-width:2560px)
{
  .swiper-button-next {
    right: -2rem;
  }
  .swiper-button-prev {
    left: -5rem;
  }
}
@media(max-width:1600px)
{
  .swiper-button-next {
    right: -2rem;
  }
  .swiper-button-prev {
    left: -4rem;
  }
}
@media(max-width:1300px)
{
  .swiper-button-next {
    right: 0rem;
  }
  .swiper-button-prev {
    left: -4rem;
  }
}
@media(max-width:1200px)
{
  .swiper-button-next {
    right: -2rem;
  }
  .swiper-button-prev {
    left: -3rem;
  }
}
@media(max-width:1050px)
{
  .swiper-button-next {
    right: -2rem;
  }
  .swiper-button-prev {
    left: -3rem;
  }
}
@media(max-width:991px)
{
  .swiper-button-next {
    right: -1rem;
  }
  .swiper-button-prev {
    left: -4rem;
  }
}
@media(max-width:900px)
{
  .swiper-button-next {
    right: -2rem;
  }
  .swiper-button-prev {
    left: -3rem;
  }
}
@media(max-width:768px)
{
  .swiper-button-next {
    right: -3.5rem;
  }
  .swiper-button-prev {
    left: -3rem;
  }
}
@media((min-width:500px) and (max-width:700px))
{
  .swiper-button-next {
    right: -3rem;
  }
  .swiper-button-prev {
    left: -1.4rem;
  } 
}
@media(max-width:500px)
{
  .swiper-button-next {
    right: 3rem;
  }
  .swiper-button-prev {
   margin-left: -18px !important;
    
  }

  .swiper-button-prev{
    display: none;
  }
  
  .swiper-button-next{
    display: none;
  }

 
}
@media(max-width:400px)
{
  .swiper-button-next {
    left:21rem !important;
  }
  .swiper-button-prev {
    left: -1rem !important;
  }
}

@media(min-width:400px ) and (max-width:500px)
{
  .swiper-button-next {
    left:23rem !important;
  }
  .swiper-button-prev {
    left: -1rem !important;
  }
}

@media (max-width:768px){
  .swiper-button-prev{
    display: none;
  }
  
  .swiper-button-next{
    display: none;
  }
}

