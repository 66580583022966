
.sort {
  text-align: right;
}
#dropdown-basic,
#dropdown-basic:focus,
#dropdown-basic:active,
#dropdown-basic:after,
#dropdown-basic:hover {
  background-color: #fff;
  border: none;
  color: black;
  border-color: #fff;
  line-height: 0rem;
  
}
.absolute {
  top: 25%;
  position:absolute;
}
.relative {
  position: relative;
}

.container.search-mr.mb-5{
  display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

}
 



.sec-page-row{
  max-width: 990px;
  width:90%;
  margin-left: 70px !important;
}
.btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25) !important;
}


.pro-filters {
  background-color: #f7f2f2;
padding: 20px 0px 50px 20px;
width: 300px;
}
.filter-h {
  font-family: "lexReg2";
  font-weight: 600;
  color: #073448;
  
}


.filter-chevron a,
.filter-chevron a:focus,
.filter-chevron a:hover {
  color: #000000;
}
.filter-props {
  padding-right: 11%;
}
.list-group {
  display: block;
  transition: 0.3s;
}
.list-group-h {
  display: none;
  transition: 0.3s;
}

.price-text {
  font-family: 'lexReg';
  font-size: 13px;
  line-height: 2rem;
}
.list-group a.active-filter {
  background-color: #073448;
  transition: 0.3s;
  color: #fff;
}



hr.line {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  background-color: #000000;
  color: #000000;
  width: 80%;
 
}
.price-slider {
  padding-left: 8%;
  width: 88%;
}

.MuiSlider-rail,
.MuiSlider-track {
  color: #073448;
}
.MuiSlider-thumb {
  color: #149444;
}

.list-group a.list-group-item,
.list-group a.list-group-item:hover {
  font-size: 90%;
  height: 10%;
  width: 100%;
  margin-top: 5%;
  border-radius: 10px;
  border: 1px solid #073448;
  padding: 6px;
  transition: 0.3s;
}



#closeButton {
  display: none ;
}

.showOptions {
  display: none ;
}

.price-input {
  border-radius: 5px;
  border: 1px solid #073448;
  width: 55px;
  height: 30px;
  font-size: 70%;
  text-align: center;
  margin-right: .5rem;
}

.mr {
  margin-right: 4%;
}
.prop-light{
  font-weight: bold;
  font-size: 15px;
}
.filter-btn {
  width: 70px;
  height: 30px;
  padding: 7%;
  background-color: #073448;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 9px;
  padding: 4px;
  text-transform: uppercase;
  font-family: "lexReg2";
  font-weight: 600;
}

#counter-btn {
  border-radius: 15px;
  width: 30px;
  background-color: #f7f2f2;
  border:0.5px solid #073448;
  text-align: center !important;
  color: #073448 !important;
  /* padding-bottom: 3px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.counted{
  font-family: 'lexSemi';
}
.counter-row {
  margin-bottom: 6px;
  width: 120%;
}
.counter-span {
  font-size: 90%;

  width: 150px;
  border-radius: 10px;
  border: 1px solid #073448;
  padding: 6px;
  background-color: #fff;
  font-family: "lexReg";
}
#map {
  margin-bottom: 40px;
  margin-top: 40px;
  height: 300px;
  width: 300px;
}


.row-page2{
  max-width: 85% !important;
    margin-left: 75px !important;
}

@media((min-width:991px) and (max-width:1200px))
{
  .counter-span{
    width: 130px;
  }
  .filter-btn{
    width: 45px;
  }
  .price-input {
    width: 45px;
    margin-right: .5rem;
  }
  .pro-filters{
    width: 250px;
  }
  #map{
    width: 250px;
  }
}
@media (max-width: 767px) {
  .sort{
    text-align: left;
  }
  .sub-cat-pro-h {
    font-size: 170%;
    padding-top: 5%;
  }
  .filter-btn {
    padding: 1%;
  }
  .mr {
    margin-right: 0%;
  }


  
  
    .container-to-hide-on-mobile{
      display: none ;
     position: fixed !important;
     top: 0;
     right: 0; 
     width: 330px !important;
     height: 100vh;
    background-color: white;
    overflow-y:scroll;
    overflow-x: hidden;
    transition: right 0.5s ease-in-out; /* Add a transition effect */
    }
  
    #closeButton {
      display: block;
      position: relative;
      margin-bottom: -10px;
     top:5px;
      height:40px;
      width:40px;
    }
  
    .showOptions {
      display: block ;
      background-color: #073448 !important;
      color: white !important;
      position: relative;
      top:-200px;
      left:380px;
    }
 
}


@media (max-width:768px){
 
  .showOptions {
    display: block ;
    height: 40px;
    font-size: 12px !important;
    background-color: #073448 !important;
    color: white !important;
    position: relative;
    top:00px;
    left:100px;
    margin-bottom: 30px;
  }
  
}

@media (max-width:768px){

  .swiper-container{
    /* width:70vw; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popular-cities{
    margin-top: 55px !important;
  }

  

  .swiper-button-next-1{
margin-top: -75px !important;
margin-left: -25px !important;

  }

  .swiper-button-prev-1{
    margin-top: -80px !important;
    margin-left: -35px;
   
      }

      
}

@media (max-width:468px){

  .swiper-container{
    /* width:90vw; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popular-cities{
    margin-top: 55px !important;
  }

  .swiper-button-next-1{
margin-top: 200px !important;
margin-left: -55px !important;

  }

  .swiper-button-prev-1{
    margin-top: 200px !important;
   
      }

  

  .swiper-slide-active{
    padding:0 !important;
  }
 
  .showOptions {
    display: block ;
    height: 30px;
    font-size: 12px !important;
    background-color: #073448 !important;
    color: white !important;
    position: relative;
    top:0px;
    left:125px;
    margin-bottom: 30px;
  }
  
  .card-body{
    margin-left: 100px;
  }
 

 
  .count-properties{
    width:50% !important;
  }

  .popular-cities{
    text-align: center;
    margin-left: 20px;
  }
}


@media (min-width:900px){
 

.properties2-img-container{
  display: flex !important;
  justify-content: space-between;
  
  }
  
  .map-filters{
    margin-left: 50px;
  }

  .prop-header {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .prop-header-sub {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
  }
  
}
